import React, { Component } from 'react';

import ClientStripe from '../../assets/images/circle-stripe.png';
import ServiceList from './serviceList';

class ServicesOffered extends Component {
  constructor() {
    super();
    this.state = {
      startCounter: false,
    };
  }

  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ startCounter: true });
    }
  };

  render() {
    return (
      <section
        className='about-one '
        style={{
          paddingBottom: '120px',
        }}
        id='About'
      >
        <img src={ClientStripe} className='about-one__circle' alt='' />
        <div className='container text-center'>
          <div className='block-title text-center'>
            <h2 className='block-title__title'>
              SERVICES/AREA OF EXPERTISE
              <br />
            </h2>
          </div>
          <p
            className='about-one__text'
            style={{
              paddingBottom: '80px',
            }}
          >
            Avadhani and Associates have experienced people with expertise
            covering many different aspects of law and providing a personal
            approach and service. We offer varied legal services including
            vetting documents, legal opinion, drafting of pleadings, written
            arguments, legal advice, appearance in All High Courts, Supreme
            Court, National Company Law Tribunals, National Company Law
            Appellate Tribunal, Arbitration, &amp; Mediation. Our services list
            gives more details of these. One of our dedicated experts is only a
            call or email away.
            <br />
            We are here to help you.
          </p>
        </div>
        <ServiceList />
      </section>
    );
  }
}

export default ServicesOffered;
