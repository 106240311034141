import 'swiper/css/swiper.css';

import React from 'react';
import Swiper from 'react-id-swiper';

const ServiceList = () => {
  const params = {
    slidesPerView: 5,
    loop: true,
    speed: 1000,
    spaceBetween: 30,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },

    // Responsive breakpoints
    breakpoints: {
      1024: {
        slidesPerView: 5,
      },
      768: {
        slidesPerView: 4,
      },
      640: {
        slidesPerView: 3,
      },
      320: {
        slidesPerView: 2,
      },
    },
  };

  return (
    <section className='course-category-two'>
      <div className='container text-center'>
        <div className='inner-container'>
          <div className='course-category-two__carousel'>
            <Swiper {...params}>
              <div className='item'>
                <div className='course-category-two__single color-3'>
                  <div className='course-category-two__icon'></div>
                  <h3 className='course-category-two__title'>
                    <a href='#none'>Company Law</a>
                  </h3>
                </div>
              </div>
              <div className='item'>
                <div className='course-category-two__single color-4'>
                  <div className='course-category-two__icon'></div>
                  <h3 className='course-category-two__title'>
                    <a href='#none'>Family Law</a>
                  </h3>
                </div>
              </div>
              <div className='item'>
                <div className='course-category-two__single color-5'>
                  <div className='course-category-two__icon'></div>
                  <h3 className='course-category-two__title'>
                    <a href='#none'>Wills and Probate</a>
                  </h3>
                </div>
              </div>
              <div className='item'>
                <div className='course-category-two__single color-6'>
                  <div className='course-category-two__icon'></div>
                  <h3 className='course-category-two__title'>
                    <a href='#none'>Civil litigation</a>
                  </h3>
                </div>
              </div>
              <div className='item'>
                <div className='course-category-two__single color-1'>
                  <div className='course-category-two__icon'></div>
                  <h3 className='course-category-two__title'>
                    <a href='#none'>Property Matters</a>
                  </h3>
                </div>
              </div>
              <div className='item'>
                <div className='course-category-two__single color-2'>
                  <div className='course-category-two__icon'></div>
                  <h3 className='course-category-two__title'>
                    <a href='#none'>Arbitration</a>
                  </h3>
                </div>
              </div>
              <div className='item'>
                <div className='course-category-two__single color-3'>
                  <div className='course-category-two__icon'></div>
                  <h3 className='course-category-two__title'>
                    <a href='#none'>Immigration</a>
                  </h3>
                </div>
              </div>
              <div className='item'>
                <div className='course-category-two__single color-4'>
                  <div className='course-category-two__icon'></div>
                  <h3 className='course-category-two__title'>
                    <a href='#none'>Society Matters</a>
                  </h3>
                </div>
              </div>
              <div className='item'>
                <div className='course-category-two__single color-1'>
                  <div className='course-category-two__icon'></div>
                  <h3 className='course-category-two__title'>
                    <a href='#none'>Constitution</a>
                  </h3>
                </div>
              </div>
              <div className='item'>
                <div className='course-category-two__single color-2'>
                  <div className='course-category-two__icon'></div>
                  <h3 className='course-category-two__title'>
                    <a href='#none'>Tax Laws</a>
                  </h3>
                </div>
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceList;
