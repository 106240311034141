import React from 'react';
import intern1 from '../../assets/images/Interns/mahathi.jpg';
import intern10 from '../../assets/images/Interns/kirti.jpg';
import intern11 from '../../assets/images/Interns/amith.jpg';
import intern12 from '../../assets/images/Interns/shaik.jpg';
import intern13 from '../../assets/images/Interns/mayukh.jpg';
import intern2 from '../../assets/images/Interns/bolla.jpg';
import intern3 from '../../assets/images/Interns/krishna.jpg';
import intern4 from '../../assets/images/Interns/chakradhar.jpg';
import intern5 from '../../assets/images/Interns/maddepally.jpg';
import intern6 from '../../assets/images/Interns/sanhitha.jpg';
import intern7 from '../../assets/images/Interns/dharini.jpg';
import intern8 from '../../assets/images/Interns/nandini.jpg';
import intern9 from '../../assets/images/Interns/maranganty.jpg';

const TeamInterns = () => {
  return (
    <section className='team-one team-one__become-teacher' id='team-section'>
      <div className='container'>
        <div className='container text-center'>
          <div className='block-title text-center'>
            <h2 className='block-title__title'>
              OUR INTERNS
              <br />
            </h2>
          </div>
          <h1
            className='blog-one__title'
            style={{
              paddingBottom: '0px',
            }}
          >
            Internship programme at Avadhani and Associates:
          </h1>
          <p className='about-one__text'>
            The internship is aimed at providing a learning opportunity to the
            young students. It is the best way to put theoretical knowledge to
            practice. At Avadhani and Associates, we offer summer and winter
            internships for the talent from top law schools in India. It is a
            four to eight-week internship program where students gain practical
            knowledge on client interactions, taking case briefs, research
            works, court procedures, drafting and get mentored by subject matter
            experts. Upon successful completion, interns get an Internship
            completion certificate. Some of our successful interns are:
          </p>
        </div>
        <div className='row'>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={intern1} alt='' />
              </div>
              <div className='team-one__content Mahathi'>
                <h2 className='testimonials-one__name'>Mahathi Ambadipudi</h2>
                <p className='team-one__designation'>Intern</p>
                <p className='about-one__text'>
                  3rd year law student at ICFAI Law School, Hyderabad
                </p>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={intern2} alt='' />
              </div>
              <div className='team-one__content vijay'>
                <h2 className='team-one__name'>Bolla Moksha Samhitha</h2>
                <p className='team-one__designation'>Intern</p>
                <p className='about-one__text'>
                  4th year law student at Damodaram Sanjivayya National Law
                  University (DSNLU), Visakhapatnam
                </p>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={intern3} alt='' />
              </div>
              <div className='team-one__content Krishna'>
                <h2 className='team-one__name'>D. Krishna Tejasree</h2>
                <p className='team-one__designation'>Intern</p>
                <p className='about-one__text'>
                  3rd year law student at ICFAI Law School, Hyderabad
                </p>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={intern4} alt='' />
              </div>
              <div className='team-one__content'>
                <h2 className='team-one__name'>M. Chakradhar Reddy</h2>
                <p className='team-one__designation'>Intern</p>
                <p className='about-one__text'>
                  4th year law student at Damodaram Sanjivayya National Law
                  University (DSNLU), Visakhapatnam
                </p>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={intern5} alt='' />
              </div>
              <div className='team-one__content Maddepally'>
                <h2 className='team-one__name'>Maddepally Pragna</h2>
                <p className='team-one__designation'>Intern</p>
                <p className='about-one__text'>
                  <p className='about-one__text'>
                    2nd year law student from NALSAR University of Law,
                    Hyderabad
                  </p>
                </p>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={intern6} alt='' />
              </div>
              <div className='team-one__content Sanhitha'>
                <h2 className='team-one__name'>Ms. Sanhitha Reddy Narayan </h2>
                <p className='team-one__designation'>Intern</p>
                <p className='about-one__text'>
                  2nd year Law student at Jindal Global Law School, NCR of Delhi
                </p>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={intern7} alt='' />
              </div>
              <div className='team-one__content Sanhitha'>
                <h2 className='team-one__name'>R Dharini</h2>
                <p className='team-one__designation'>Intern</p>
                <p className='about-one__text'>
                  4th year law student at Maharashtra National Law University,
                  Nagpur
                </p>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={intern8} alt='' />
              </div>
              <div className='team-one__content Sanhitha'>
                <h2 className='team-one__name'>Nandini Sharma</h2>
                <p className='team-one__designation'>Intern</p>
                <p className='about-one__text'>
                  2nd year law student at Symbiosis Law School, Nagpur
                </p>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={intern9} alt='' />
              </div>
              <div className='team-one__content Sanhitha'>
                <h2 className='team-one__name'>Maranganty Sreelakshmi</h2>
                <p className='team-one__designation'>Intern</p>
                <p className='about-one__text'>
                  4th year law student at Vivekananda Institute of Professional
                  Studies.
                </p>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={intern10} alt='' />
              </div>
              <div className='team-one__content Sanhitha'>
                <h2 className='team-one__name'>Kirti Bajaj </h2>
                <p className='team-one__designation'>Intern</p>
                <p className='about-one__text'>
                  4th year law student at Maharashtra National Law University,
                  Nagpur
                </p>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={intern11} alt='' />
              </div>
              <div className='team-one__content Sanhitha'>
                <h2 className='team-one__name'>Amith Bachu</h2>
                <p className='team-one__designation'>Intern</p>
                <p className='about-one__text'>
                  2nd year law student at Symbiosis Law School, Nagpur
                </p>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={intern12} alt='' />
              </div>
              <div className='team-one__content Sanhitha'>
                <h2 className='team-one__name'>Shaik Tameem Ansari</h2>
                <p className='team-one__designation'>Intern</p>
                <p className='about-one__text'>
                  2nd year law student at Symbiosis law school, Nagpur
                </p>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={intern13} alt='' />
              </div>
              <div className='team-one__content Sanhitha'>
                <h2 className='team-one__name'>Mayukh Ceetakoru</h2>
                <p className='team-one__designation'>Intern</p>
                <p className='about-one__text'>
                  4th year law student at NALSAR University of law, Hyderabad
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamInterns;
