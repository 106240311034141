import React from 'react';
import book1 from '../../assets/images/books/book1.jpg';
import book10 from '../../assets/images/books/book10.jpg';
import book11 from '../../assets/images/books/book11.jpg';
import book12 from '../../assets/images/books/book12.jpg';
import book13 from '../../assets/images/books/book13.jpg';
import book14 from '../../assets/images/books/book14.jpg';
import book15 from '../../assets/images/books/book15.jpg';
import book2 from '../../assets/images/books/book2.jpg';
import book3 from '../../assets/images/books/book3.jpg';
import book4 from '../../assets/images/books/book4.jpg';
import book5 from '../../assets/images/books/book5.jpg';
import book6 from '../../assets/images/books/book6.jpg';
import book7 from '../../assets/images/books/book7.jpg';
import book8 from '../../assets/images/books/book8.jpg';
import book9 from '../../assets/images/books/book9.jpg';

const LatestNewsAndArticles = () => {
  return (
    <section className='blog-one  blog-one__home-two'>
      <div className='container'>
        <div className='block-title text-center'>
          <h2 className='block-title__title'>
            Our latest news <br />& articles
          </h2>
        </div>
        <div className='row'>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={book1} alt='' />
              </div>
              <div className='blog-one__content text-center intellectual'>
                <h2 className='blog-one__title'>
                  Law of Intellectual Property Law
                </h2>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={book2} alt='' />
              </div>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title scienceofevidenece'>
                  Science of evidence (theory and practice)
                </h2>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={book3} alt='' />
              </div>
              <div className='blog-one__content text-center witness'>
                <h2 className='blog-one__title'>
                  LESSONS ON LAW OF WITNESSES AND EVIDENCE
                </h2>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={book4} alt='' />
              </div>
              <div className='blog-one__content text-center HinduFamily'>
                <h2 className='blog-one__title'>
                  Lesson On Law Of Hindu Family &amp; Property: Principle And
                  Procedure [Edition 2020-2021]
                </h2>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={book5} alt='' />
              </div>
              <div className='blog-one__content text-center Document'>
                <h2 className='blog-one__title'>
                  Law Of Documents And Evidence <br />[ Edition 2019 - 2020 ]
                </h2>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={book6} alt='' />
              </div>
              <div className='blog-one__content text-center civilwrongs'>
                <h2 className='blog-one__title civilwrongs'>
                  Law Of Civil Wrongs
                </h2>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={book7} alt='' />
              </div>
              <div className='blog-one__content text-center victimCompensation'>
                <h2 className='blog-one__title'>
                  Sentencing And Victim Compensation Principles And Practices
                </h2>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={book8} alt='' />
              </div>
              <div className='blog-one__content text-center trial'>
                <h2 className='blog-one__title'>
                  Criminal Trial &amp; Enquiries (Law, Practice &amp; Procedure)
                </h2>
              </div>
            </div>
          </div>

          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={book9} alt='' />
              </div>
              <div className='blog-one__content text-center Enquiries'>
                <h2 className='blog-one__title'>
                  Criminal Trial &amp; Enquiries
                </h2>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={book10} alt='' />
              </div>
              <div className='blog-one__content text-center act'>
                <h2 className='blog-one__title'>
                  NEGOTIABLE INSTRUMENTS ACT 1881 IN TELUGU
                </h2>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={book11} alt='' />
              </div>
              <div className='blog-one__content text-center BurdenOfProof'>
                <h2 className='blog-one__title'>LESSONS ON BURDEN OF PROOF</h2>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={book12} alt='' />
              </div>
              <div className='blog-one__content text-center investigation'>
                <h2 className='blog-one__title'>
                  Criminal Investigation (Law, Practice &amp; Procedure)
                </h2>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={book13} alt='' />
              </div>
              <div className='blog-one__content text-center practice'>
                <h2 className='blog-one__title'>
                  Lessons On Proof Of Facts: Law &amp; Practice [Edition
                  2020-2021]
                </h2>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={book14} alt='' />
              </div>
              <div className='blog-one__content text-center Emphasis'>
                <h2 className='blog-one__title'>
                  Law Of Documents &amp; Evidence With Special Emphasis On
                  Questioned Documents [Edition 2020-2021]
                </h2>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={book15} alt='' />
              </div>
              <div className='blog-one__content text-center civil'>
                <h2 className='blog-one__title '>
                  Civil Adjudication Law, Practice and Procedure
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LatestNewsAndArticles;
