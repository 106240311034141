import React, { Component } from 'react';

import AreasOfWork from './areasOfWork';
import ClientStripe from '../../assets/images/circle-stripe.png';

class OurClientele extends Component {
  constructor() {
    super();
    this.state = {
      startCounter: false,
    };
  }

  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ startCounter: true });
    }
  };

  render() {
    return (
      <section
        className='about-one '
        style={{
          paddingBottom: '120px',
        }}
        id='ourClient'
      >
        <img src={ClientStripe} className='about-one__circle' alt='' />
        <div className='container text-center'>
          <div className='block-title text-center'>
            <h2 className='block-title__title'>
              OUR CLIENTELE
              <br />
            </h2>
          </div>
          <p className='about-one__text'>
            Our clients are our most valuable asset. This is why we always
            invest in our clients beyond what is expected, when providing them
            with friendly, efficient cost effective and quality legal advice. We
            always make an effort to understand our clients’ needs, objectives
            and tolerance for risk in each situation. To be more specific about
            whom our clients are,
            <br /> they tend to be individuals and people’s businesses.
          </p>
          <h1
            className='blog-one__title'
            style={{
              paddingBottom: '100px',
            }}
          >
            These are a few of the areas our clients work as or are involved in:
          </h1>
        </div>
        <AreasOfWork />
      </section>
    );
  }
}

export default OurClientele;
