import 'swiper/css/swiper.css';

import BandlaGanesh from '../../assets/images/testimonials/bandla-ganesh.jpg';
import KrishnaReddy from '../../assets/images/testimonials/krishna-reddy.jpg';
import Prabhavathi from '../../assets/images/testimonials/prabhavathi.jpg';
import RajenderReddy from '../../assets/images/testimonials/rajender-reddy.jpg';
import React from 'react';
import SrinivasaRaju from '../../assets/images/testimonials/srinivasa-raju.jpg';
import Srinivasan from '../../assets/images/testimonials/srinivasan.jpg';
import Swiper from 'react-id-swiper';
import VenkateshwarRao from '../../assets/images/testimonials/venkateshwar-rao.jpg';
import Vishwanath from '../../assets/images/testimonials/vishwanath.jpg';
import quoteImage from '../../assets/images/qoute-1-1.png';
import unknown from '../../assets/images/testimonials/unknown.jpg';

const testimonialsOne = () => {
  const params = {
    slidesPerView: 3,
    loop: false,
    speed: 1000,
    spaceBetween: 30,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.swiper-pagination relative',
      clickable: true,
    },

    // Responsive breakpoints
    breakpoints: {
      1024: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 2,
      },
      640: {
        slidesPerView: 2,
      },
      320: {
        slidesPerView: 1,
      },
    },
  };

  return (
    <section className='testimonials-one testimonials-one__home-three'>
      <div className='container'>
        <div className='block-title text-center'>
          <h2 className='block-title__title'>testimonials</h2>
        </div>
        <div className='testimonials-one__carousel '>
          <Swiper {...params}>
            <div className='item'>
              <div className='testimonials-one__single vishwanath'>
                <img
                  src={Vishwanath}
                  alt=''
                  className='testimonials-one__img'
                />
                <h3 className='testimonials-one__name'>Vishwanath Polepeddi</h3>
                <p className='testimonials-one__designation'>Client</p>
                <div className='testimonials-one__quote Review'>
                  <img src={quoteImage} alt='' />
                </div>
                <p className='team-one__image vishwanath'>
                  When you approach them they give a feeling of COMFORT They
                  listen to you with Care and give you tons of CONFIDENCE
                  “Avadhani Associates”, is complete ASSURANCE
                </p>
                {/* <img src={team1} alt='' className='testimonials-one__img' />
                <h3 className='testimonials-one__name'>Vishwanath Polepeddi</h3>
                <p className='testimonials-one__designation'>Client</p> */}
              </div>
            </div>
            <div className='item'>
              <div className='testimonials-one__single raju'>
                <img
                  src={SrinivasaRaju}
                  alt=''
                  className='testimonials-one__img'
                />
                <h3 className='testimonials-one__name'>
                  DR. V. SRINIVASA RAJU
                </h3>
                <p className='testimonials-one__designation'>
                  CHAIRMAN, DELHI PUBLIC SCHOOL
                  <br />
                  BHIMAVARAM
                </p>
                <div className='testimonials-one__quote'>
                  <img src={quoteImage} alt='' />
                </div>
                <p className='team-one__image raju'>
                  I am pleased to testify the selfless and yeomen services of
                  Sri V.S.R.Avadhani, Advocate, Avadhani & Associates. The
                  personalized legal services that he has offered for me is
                  beyond my expectations and I have always had the experience of
                  painless litigations. I have found him to be a very humble
                  person who is ever ready to render his services to poor and
                  down trodden. I wish him all success in all his endeavours.
                </p>
              </div>
            </div>
            <div className='item'>
              <div className='testimonials-one__single vishwanath rao'>
                <img
                  src={VenkateshwarRao}
                  alt=''
                  className='testimonials-one__img '
                />
                <h3 className='testimonials-one__name'>L.Venkateshwar Rao</h3>
                <p className='testimonials-one__designation'>
                  Industrialist from Hyderabad
                  <br />
                  Client
                </p>
                <div className='testimonials-one__quote'>
                  <img src={quoteImage} alt='' />
                </div>
                <p className='team-one__image rao'>
                  We have Shri Seetharama Avadhani Sir and Soubhagya Valli Ma'am
                  assisting us for a couple of years in a strenuous case against
                  our business tenants. Given the usual ambiguity with judicial
                  Jargon and abnormal delays in hearings that cause mental
                  havoc, we switched advocates frequently. We finally were
                  relieved to entrust our case here. Shri Avadhani Sir and his
                  team have not only been satisfyingly responsive but have also
                  been Highly Reliable with Relevent Exceptional Experience.
                  Highly recommended for your legal needs.
                </p>
              </div>
            </div>
            <div className='item'>
              <div className='testimonials-one__single krishna'>
                <img
                  src={KrishnaReddy}
                  alt=''
                  className='testimonials-one__img'
                />
                <h3 className='testimonials-one__name'>Krishna Reddy</h3>
                <p className='testimonials-one__designation'>Client</p>
                <div className='testimonials-one__quote'>
                  <img src={quoteImage} alt='' />
                </div>
                <p className='team-one__image krishna'>
                  I have found the right solution for my problem in this office
                  and they treat clients very well anytime on phone also. After
                  seeing the office and the way you received I got the
                  confidence that my problem will be solved
                </p>
              </div>
            </div>
            <div className='item'>
              <div className='testimonials-one__single sirini'>
                <img
                  src={Srinivasan}
                  alt=''
                  className='testimonials-one__img'
                />
                <h3 className='testimonials-one__name'>R.Srinivasan</h3>
                <p className='testimonials-one__designation'>Client</p>
                <div className='testimonials-one__quote'>
                  <img src={quoteImage} alt='' />
                </div>
                <p className='team-one__image'>
                  Avadhani & Associate helped us at every stage in our pursuit
                  for justice, from hand holding in the initial stages through
                  guiding us to build a stronger case in the midst of challenges
                  and finally help us reach a closure. It wasn't easy
                  considering we were a group of more than twenty diverse
                  individuals connected only by a common cause. We were
                  overwhelmed when your firm decided to accept our brief not as
                  just another case, but as a cause that deserved to be helped
                  and a case worth fighting for. Your patience, perseverance,
                  passion, and personal attention from the Top are what made us
                  travel this far successfully. It's not only justice that we
                  got with your help, but life-saving outcome, for most of our
                  group members were on the verge of financial ruin. You have
                  delivered us hope, confidence has returned to our life!
                </p>
              </div>
            </div>
            <div className='item'>
              <div className='testimonials-one__single bandala'>
                <img
                  src={BandlaGanesh}
                  alt=''
                  className='testimonials-one__img'
                />
                <h3 className='testimonials-one__name'>Bandla Ganesh</h3>
                <p className='testimonials-one__designation'>
                  Indian Film Actor
                  <br />
                  Client
                </p>
                <div className='testimonials-one__quote'>
                  <img src={quoteImage} alt='' />
                </div>
                <p className='team-one__image '>
                  అద్భుతమైన ప్రేమ, అనురాగం, అప్యాయత మనది అనే నమ్మకం, మంచి
                  బరోసా,ఎల్లవేళలా తన క్లయింట్ ను కాపాడుతారనే అందమైన ఆలోచనతో
                  కూడిన అభిప్రాయం. మీరు ఎప్పుడూ ఇలాగే ఉండాలని ఇంకా అభివృద్ధి
                  చెంది మంచి సమాజానికి, మంచితనానికి పునాధై ధర్మానికి,న్యాయానికి
                  న్యాయం జరుగుతుందని నమ్మకంతో మీ దగ్గరకు వస్తున్న ప్రతి ఒక్కరికి
                  ఆనందాన్ని కలుగజేయాలని కోరుకొంటూ.
                </p>
              </div>
            </div>

            <div className='item'>
              <div className='testimonials-one__single Reddy'>
                <img
                  src={RajenderReddy}
                  alt=''
                  className='testimonials-one__img'
                />
                <h3 className='testimonials-one__name'>Rajender Reddy B</h3>
                <p className='testimonials-one__designation'>
                  Florida USA
                  <br />
                  Client
                </p>
                <div className='testimonials-one__quote'>
                  <img src={quoteImage} alt='' />
                </div>
                <p className='team-one__image '>
                  My experiences with Avadhani Associates and their team were
                  stupendous through and through. These people know their craft
                  and walk the extra mile for you. I consulted with Avadhani
                  Asociates on various occasions and each one of them was
                  handled with utmost care and professionalism. I also
                  appreciated working with people that care for you on a
                  personal level instead of just handling you as a “case“.
                  Highly recommend
                </p>
              </div>
            </div>

            <div className='item'>
              <div className='testimonials-one__single Prabhavathi'>
                <img
                  src={Prabhavathi}
                  alt=''
                  className='testimonials-one__img'
                />
                <h3 className='testimonials-one__name'>Prabhavathi</h3>
                <p className='testimonials-one__designation'>Client</p>
                <div className='testimonials-one__quote'>
                  <img src={quoteImage} alt='' />
                </div>
                <p className='team-one__image '>
                  Hi… Iam prabhavathi pothu from Hyderabad… I went to Avadhani
                  and associates of 2 different cases … Avadhani sir explained
                  everything how the case gonna be and the consequences of the
                  case very honestly and guided me the right way.. sir takes
                  care of his client…very impressive for I would highly
                  recommend Avadhani sir to everyone…
                </p>
              </div>
            </div>

            <div className='item'>
              <div className='testimonials-one__single Manohar'>
                <img src={unknown} alt='' className='testimonials-one__img' />
                <h3 className='testimonials-one__name'>Manohar Kumar</h3>
                <p className='testimonials-one__designation'>Client</p>
                <div className='testimonials-one__quote'>
                  <img src={quoteImage} alt='' />
                </div>
                <p className='team-one__image '>
                  I would highly recommend the legal team at Avadani Associates;
                  this group is highly professional, dedicated individuals who
                  have exemplary knowledge and understanding of Indian legal
                  requirements and can guide the NRIs through Indian legal
                  issues. They have helped resolve several property litigations
                  and have become my trusted advisors during any significant
                  deals. I am happy to have them as my legal representative and
                  guide in India.
                </p>
              </div>
            </div>

            <div className='item'>
              <div className='testimonials-one__single Kushal'>
                <img src={unknown} alt='' className='testimonials-one__img' />
                <h3 className='testimonials-one__name'>Kushal</h3>
                <p className='testimonials-one__designation'>Client</p>
                <div className='testimonials-one__quote'>
                  <img src={quoteImage} alt='' />
                </div>
                <p className='team-one__image '>
                  In times where people are looking at making money, sell their
                  services without having any positive impact on the buyer.
                  There are still few people who look at making their clients
                  satisfied rather then minting money. when looking for an
                  advocate for a case, we came across Advocate Vijay Bhasker,
                  from (Avadhani and Associates) is down to earth I could say,
                  he heard the case gave us the exact inputs and also the max
                  risk involved, if any. Advocate Vijay Bhasker is one who does
                  not sell his services, rather acts as a consultant giving the
                  right advice and leaves on us to decide to go with him or any
                  other advocate. I was fortune enough to go with him after his
                  advice, the best part he does not try to drag on the case to
                  mint money. what ever be the court decision, we are happy we
                  were in the right and safe hands and not taken for ride. I
                  would surely like to recommend Advocate to anyone who is
                  looking for right solution, in fact I have referred few of my
                  friends who needed some legal assistance.
                </p>
              </div>
            </div>
            <div className='item'>
              <div className='testimonials-one__single Kushal'>
                <img src={unknown} alt='' className='testimonials-one__img' />
                <h3 className='testimonials-one__name'>Parupalli Gopichand</h3>
                <p className='testimonials-one__designation'>Client</p>
                <div className='testimonials-one__quote'>
                  <img src={quoteImage} alt='' />
                </div>
                <p className='team-one__image '>
                  Avadhani & Associates is a well experienced Firm and the
                  discussion with Sr. advocate Vijay Bhaskar Reddy Garu was
                  first interaction, where in which he had cleared all my doubts
                  and made me confidant by explaining the end to End process of
                  filing the case in different courts. I confidently say that
                  The Avadhani & Associates had s one place where you can get
                  the Trust and Confidant in terms of Legal Cases.
                </p>
              </div>
            </div>
            <div className='item'>
              <div className='testimonials-one__single Kushal'>
                <img src={unknown} alt='' className='testimonials-one__img' />
                <h3 className='testimonials-one__name'>M Murali</h3>
                <p className='testimonials-one__designation'>Client</p>
                <div className='testimonials-one__quote'>
                  <img src={quoteImage} alt='' />
                </div>
                <p className='team-one__image '>
                  My experience with Avadhani associates by Vijay Bhaskar is his
                  in depth knowledge, experience, and well-versed in his field
                  or area of advocacy. He has ability to communicate complex
                  ideas clearly and effectively, both in person and in written
                  form. He contributes the effectiveness in representing or
                  championing the cause, whether it's in legal matters or other
                  areas of advocacy.
                </p>
              </div>
            </div>
            <div className='item'>
              <div className='testimonials-one__single Kushal'>
                <img src={unknown} alt='' className='testimonials-one__img' />
                <h3 className='testimonials-one__name'>Satish Bhaskara</h3>
                <p className='testimonials-one__designation'>Client</p>
                <div className='testimonials-one__quote'>
                  <img src={quoteImage} alt='' />
                </div>
                <p className='team-one__image '>
                  I would heartfully say that Avadhani and Associates is the
                  best of the best legal service providers I have come across.
                  Indebted to them for helping me in my case in time. Before
                  reaching out to Avadhani and Associates I had a very tough
                  time. But Sir V.S.R Avadhani Garu, with his exceptional
                  knowledgeable about the law and Judiciary System, provided me
                  the needed legal guidance and I am supported throughout my
                  tenure of Case by his team, Valli garu and others, have
                  ensured I have been given the right Justice and most
                  importantly in time. Avadhani garu and his team, Valli garu
                  and others, are exceptionally kind and courteous and very
                  Co-operative while they represented my case and guided and
                  supported me exceptionally in the court room as well as
                  personally. I strongly recommend them for any legal advices.
                  Thank you Avadhani and Associates.
                </p>
              </div>
            </div>
            <div className='item'>
              <div className='testimonials-one__single Kushal'>
                <img src={unknown} alt='' className='testimonials-one__img' />
                <h3 className='testimonials-one__name'>Sravan Kumar Poienti</h3>
                <p className='testimonials-one__designation'>Client</p>
                <div className='testimonials-one__quote'>
                  <img src={quoteImage} alt='' />
                </div>
                <p className='team-one__image '>
                  Avadhani and Associates is the best of the best legal service
                  providers in Hyderabad. Hands down. V.S.R Avadhani sir is very
                  knowledgeable about law and runs his office with utmost
                  professionalism and integrity. Avadhani garu and his team
                  (Soubhagya Valli ma'am and others) are exceptional with
                  keeping us up-to-date with our case, represented us promptly
                  and effectively in the court and guided us smoothly throughout
                  the legal battle. I strongly recommend them for any legal
                  advices. Thank you Avadhani and Associates.
                </p>
              </div>
              <div className='testimonials-one__single Kushal'>
                <img src={unknown} alt='' className='testimonials-one__img' />
                <h3 className='testimonials-one__name'>Satish Bhaskara</h3>
                <p className='testimonials-one__designation'>Client</p>
                <div className='testimonials-one__quote'>
                  <img src={quoteImage} alt='' />
                </div>
                <p className='team-one__image '>
                  I would heartfully say that Avadhani and Associates is the
                  best of the best legal service providers I have come across.
                  Indebted to them for helping me in my case in time. Before
                  reaching out to Avadhani and Associates I had a very tough
                  time. But Sir V.S.R Avadhani Garu, with his exceptional
                  knowledgeable about the law and Judiciary System, provided me
                  the needed legal guidance and I am supported throughout my
                  tenure of Case by his team, Valli garu and others, have
                  ensured I have been given the right Justice and most
                  importantly in time. Avadhani garu and his team, Valli garu
                  and others, are exceptionally kind and courteous and very
                  Co-operative while they represented my case and guided and
                  supported me exceptionally in the court room as well as
                  personally. I strongly recommend them for any legal advices.
                  Thank you Avadhani and Associates.
                </p>
              </div>
            </div>
            <div className='item'>
              <div className='testimonials-one__single Kushal'>
                <img src={unknown} alt='' className='testimonials-one__img' />
                <h3 className='testimonials-one__name'>OV Ramana</h3>
                <p className='testimonials-one__designation'>Client</p>
                <div className='testimonials-one__quote'>
                  <img src={quoteImage} alt='' />
                </div>
                <p className='team-one__image '>
                  Excellent, Quite friendly and understanding patiently the
                  nature of the case and the person in particular being any
                  petitioner will be under tremendous anxiety and pressure being
                  tagged with multiple legal issues from financial institutions.
                  As a whole, this is the perfect shelter for the needy.
                </p>
              </div>
            </div>
            <div className='item'>
              <div className='testimonials-one__single Kushal'>
                <img src={unknown} alt='' className='testimonials-one__img' />
                <h3 className='testimonials-one__name'>
                  Murali Krishna Kambampati
                </h3>
                <p className='testimonials-one__designation'>Client</p>
                <div className='testimonials-one__quote'>
                  <img src={quoteImage} alt='' />
                </div>
                <p className='team-one__image '>
                  Very well organized, highly professional service. Really
                  admire their work ethics and well appreciate their excellent
                  advise.
                </p>
              </div>
            </div>
            <div className='item'>
              <div className='testimonials-one__single Kushal'>
                <img src={unknown} alt='' className='testimonials-one__img' />
                <h3 className='testimonials-one__name'>B. K. Reddy</h3>
                <p className='testimonials-one__designation'>Client</p>
                <div className='testimonials-one__quote'>
                  <img src={quoteImage} alt='' />
                </div>
                <p className='team-one__image '>
                  I am very happy to share my experience with Avadhani &
                  Associates on this platform. The firm has resource full in
                  knowledge of law, provides good court craft and deals the
                  client honestly. The responsiveness and assistance provided by
                  the firm’s team is highly appreciable. I have seen
                  debating/discussing with all advocates in their office before
                  presenting the case during arguments in the court, which I
                  feel really amazing. The analysis of the case by Sri Avadhani,
                  zeroing down the relief required, expression of confidence,
                  providing crystal clear guidance to colleague advocates/
                  partners is praiseworthy. The firm maintains punctuality,
                  transparency and reasonableness.
                </p>
              </div>
            </div>
            <div className='item'>
              <div className='testimonials-one__single Kushal'>
                <img src={unknown} alt='' className='testimonials-one__img' />
                <h3 className='testimonials-one__name'>Sridhar Prasangi</h3>
                <p className='testimonials-one__designation'>Client</p>
                <div className='testimonials-one__quote'>
                  <img src={quoteImage} alt='' />
                </div>
                <p className='team-one__image '>
                  Avadhani & Associates is a Fabulous Law Firm. They provide
                  exceptional client service, have positive word-of-mouth and a
                  team with expertise consistent with their firm’s mission. It’s
                  very efficient and easy to work with them for any Legal
                  matters.
                </p>
              </div>
            </div>
            <div className='item'>
              <div className='testimonials-one__single Kushal'>
                <img src={unknown} alt='' className='testimonials-one__img' />
                <h3 className='testimonials-one__name'>Pravin Pandey</h3>
                <p className='testimonials-one__designation'>Client</p>
                <div className='testimonials-one__quote'>
                  <img src={quoteImage} alt='' />
                </div>
                <p className='team-one__image '>
                  Very profound in legal acumen. They have a good standing in
                  the corporate legal issues and can really bring 'out of the
                  box ideas' to address the situation at hand. They are quite
                  pragmatic in deliberating client concerns. A very professional
                  firm on which you can bank on. My sincere regards for Adv
                  Avadhani and his associates.
                </p>
              </div>
            </div>
            <div className='item'>
              <div className='testimonials-one__single Kushal'>
                <img src={unknown} alt='' className='testimonials-one__img' />
                <h3 className='testimonials-one__name'>Tulasi Prasad</h3>
                <p className='testimonials-one__designation'>Client</p>
                <div className='testimonials-one__quote'>
                  <img src={quoteImage} alt='' />
                </div>
                <p className='team-one__image '>
                  The best team available for any legal matter, will get genuine
                  solutions. Headed by the most respectable person in the legal
                  world, Sri Avadhani garu.
                </p>
              </div>
            </div>
            <div className='item'>
              <div className='testimonials-one__single Kushal'>
                <img src={unknown} alt='' className='testimonials-one__img' />
                <h3 className='testimonials-one__name'>Hari Prasad</h3>
                <p className='testimonials-one__designation'>Client</p>
                <div className='testimonials-one__quote'>
                  <img src={quoteImage} alt='' />
                </div>
                <p className='team-one__image '>
                  Avadhani and Associates headed by Shri V.S.R. Avadhani Garu is
                  a premium law firm in Telangana. Avadhani Garu and myself have
                  an association of more than 10 years. I am utmost satisfied
                  with the personal experience and care being provided for my
                  cases. Professional and client convenient advice makes him
                  unique and valuable. Avadhani garu's book publications on
                  revenue, criminal and other legal fields are highly regarded
                  in India and especially Telangana. Avadhani garu's lectures
                  and counselling are used by law students, young professionals,
                  and judges. His sincerity and honest service are inspirational
                  to clients and the public at large. I highly recommend
                  Avadhani and Associates to everybody.
                </p>
              </div>
            </div>
            <div className='item'>
              <div className='testimonials-one__single Kushal'>
                <img src={unknown} alt='' className='testimonials-one__img' />
                <h3 className='testimonials-one__name'>S.Ananth</h3>
                <p className='testimonials-one__designation'>Client</p>
                <div className='testimonials-one__quote'>
                  <img src={quoteImage} alt='' />
                </div>
                <p className='team-one__image '>
                  In this modern day and age, it is extremely rare to come
                  across someone who displays humanity. I could experience this
                  in the presence of Seetharama Avadhani Sir and Soubhagya Valli
                  Madam. With a great fortune by my side, I was able to connect
                  with Sir and Madam for legal advice. Coming to know that I
                  have travelled from Tamil Nadu, Sir and Madam were extremely
                  gracious enough to take up my case and guide me at every step.
                  The attention to detail on every point discussed was just
                  impeccable. Words fall short to express my gratitude to Sir
                  and Madam for helping me out with my case. To the good souls
                  reading this review, there is no second thought needed in
                  connecting with Sir, Madam and team for your legal needs and I
                  wish you all success!
                </p>
              </div>
            </div>
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default testimonialsOne;
